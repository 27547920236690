import { render, staticRenderFns } from "./SettingPage.vue?vue&type=template&id=33d01c67"
import script from "../../controllers/setting/setting-page.js?vue&type=script&lang=js&external"
export * from "../../controllers/setting/setting-page.js?vue&type=script&lang=js&external"
import style0 from "./SettingPage.vue?vue&type=style&index=0&id=33d01c67&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports