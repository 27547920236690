import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import {
    required,
} from "vuelidate/lib/validators";
import store from '@/store';
import Vue from 'vue';

export default {
    name: "checkoutEdit",
    components: {
        MainLayout,
        Select2Multiple,
        flatPickr,
        Loading,
    },

    data() {
        return {
            checkoutData: [],
            inputData: {
                index: '',
                qr_code_no: '',
                return_date: null,
                is_return: '',
                checkout_details_id: '',
            },
            memberArr: null,
            bookArr: null,
            isError: false,
            submitted: false,
            qrSubmitted: false,
            isLoading: false,
            campusId: store.state.user.campus_master.campus_code,
            btnDisabled: false,
            inputDisabled: true,
            noDataMsg: CONFIG.NO_DATA_EXIST,
            noMemberMsg: '',
            noBookMsg: '',
            dateRequired: false,
            enableDateInput: false,
            returnDateInvalid: false,
            bookMsg: '',
            stockLimitMsg: '',
            checkoutDetailReqMsg: '',
            books: [],
            selectedType: 2,
            returnStatusArr: CONFIG.RETURN_STATUS,
            qrCodeCounts: {},
        };
    },

    validations: {
        checkoutData: {
            library_member_id: {
                required,
            },
            start_date: {
                required
            },
            end_date: {
                required
            },
            checkout_details: {
                required
            }
        },
    },

    watch: {
        'inputData.qr_code_no': function (newValue) {
            if (newValue !== '') {
                this.bookMsg = '';
            }
        }
    },

    computed: {
        bookTitles() {
            this.qrCodeCounts = {};
            return this.checkoutData.checkout_details.map(detail => this.getBookTitle(detail));
        }
    },

    methods: {
        getMember() {
            let $defaultSelectOption = [{
                'id': 0,
                'text': 'Select Member'
            }];
            axios.get("/member/getList", {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    if (res.data.data.length > 0) {
                        this.memberArr = $defaultSelectOption.concat(res.data.data);
                    } else {
                        this.memberArr = $defaultSelectOption;
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getBookTitle(detail) {
            const qrCodeNo = detail.book.qr_code_no;
            if (!this.qrCodeCounts[qrCodeNo]) {
                this.qrCodeCounts[qrCodeNo] = 0;
            }
            this.qrCodeCounts[qrCodeNo] += 1;

            if (this.qrCodeCounts[qrCodeNo] > 1) {
                return `${detail.book.title} - ${this.qrCodeCounts[qrCodeNo]}`;
            } else {
                return `${detail.book.title}`;
            }
        },

        editClone(index) {
            this.inputDisabled = false;
            let selectedData = this.checkoutData.checkout_details[index];
            this.inputData.index = index;
            this.inputData.qr_code_no = selectedData.book.qr_code_no;
            this.inputData.return_date = selectedData.return_date;
            this.inputData.is_return = selectedData.is_return;
            this.inputData.checkout_details_id = selectedData.id;
        },

        updateClone(index) {
            this.qrSubmitted = true;
            let qrCodeNo = this.inputData.qr_code_no;
            let request = {
                params: {
                    qr_code_no: qrCodeNo,
                    campus_id: this.$store.state.user.campus_id
                }
            }
            if (this.checkoutData.start_date.split(" ")[0] > this.inputData.return_date) {
                this.returnDateInvalid = true;
            } else {
                this.returnDateInvalid = false;
            }
            if (this.inputData.is_return == this.returnStatusArr.Yes && this.inputData.return_date == null) {
                this.dateRequired = true;
                this.enableDateInput = true;
            } else {
                this.dateRequired = false;
                this.enableDateInput = false;
            }
            if (this.dateRequired) {
                return;
            }
            if (this.returnDateInvalid) {
                this.enableDateInput = true;
                return;
            }
            axios.get("/book/getByQrCode", request)
                .then((res) => {
                    if (res.data && Object.keys(res.data).length > 0) {
                        let updatedBook = {
                            id: res.data.id,
                            qr_code_no: qrCodeNo,
                            title: res.data.title,
                            author: res.data.author,
                            category: {
                                name: res.data.category.name,
                            }
                        };
                        this.checkoutData.checkout_details[index].book = updatedBook;
                        this.checkoutData.checkout_details[index].return_date = this.inputData.is_return == CONFIG.RETURN_STATUS.Yes ? this.inputData.return_date : '';
                        this.checkoutData.checkout_details[index].is_return = this.inputData.is_return;
                        this.resetInputData();
                    } else {
                        this.bookMsg = 'Book not found.';
                    }
                    this.submitted = false;
                    this.qrSubmitted = false;
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        resetInputData() {
            this.qrCodeCounts = {};
            this.inputData.qr_code_no = '';
            this.inputData.return_date = null;
            this.inputData.is_return = '';
            this.inputData.checkout_details_id = '';
            this.inputDisabled = true;
            this.dateRequired = false;
            this.returnDateInvalid = false;
            this.enableDateInput = false;
        },

        removeClone(index) {
            Vue.delete(this.checkoutData.checkout_details, index);
        },

        changeEvent(val, fieldName) {
            this.$set(this, fieldName, val);

            if (fieldName == "selectedType") {
                if (val == this.returnStatusArr.Yes) {
                    this.dateRequired = true;
                    this.enableDateInput = true;
                } else {
                    this.dateRequired = false;
                    this.inputData.return_date = '';
                    this.enableDateInput = false;
                }
            }
        },

        getDetail() {
            axios.get(`checkout/detail/${this.$route.params.id}`)
                .then(res => {
                    if (res.data) {
                        this.checkoutData = res.data;
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Checkout Detail API Error", error);
                })

        },

        update() {
            this.inputDisabled = true;
            this.resetInputData();
            this.submitted = true;
            this.isLoading = true;
            if (this.checkoutData.start_date && this.checkoutData.end_date && new Date(this.checkoutData.end_date) < new Date(this.checkoutData.start_date)) {
                this.isError = true;
            } else {
                this.isError = false;
            }
            if (this.$v.checkoutData.$invalid || this.isError) {
                this.isLoading = false;
                return;
            }
            this.checkoutData.login_id = this.$store.state.user.id;
            axios.post(`checkout/update/${this.$route.params.id}`, this.checkoutData)
                .then(res => {
                    if (res.data.success == true) {
                        this.$notification.success("Checkout Form Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "checkoutList"
                        });
                    } else {
                        this.btnDisabled = false;
                        this.$notification.error('res.data.message', {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    if (error.response.status == 422) {
                        this.$notification.error(error.response.data.message, {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                    this.isLoading = false;
                })
        }
    },

    mounted() {
        this.isLoading = true;
        this.getDetail();
        this.getMember();
    },
};
