import MainLayout from "@/components/layout/admin/MainLayout.vue";
import flatPickr from 'vue-flatpickr-component';
import Select2Multiple from "v-select2-multiple-component";
import 'flatpickr/dist/flatpickr.css';
import {
  required,
  maxLength,
  helpers
} from "vuelidate/lib/validators";
import { mapState, mapMutations } from 'vuex';
import CONFIG from "@/assets/js/config.js";
import axios from "axios";
import store from '../../store';
import { VueEditor } from 'vue2-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

const inTypeKeys = helpers.withParams(
  { type: "inTypeKeys" },
  value => Object.keys(CONFIG.APPLICATION.TYPE_TXT).includes(value)
);

const inCampusGradeIds = helpers.withParams(
  {type: "inCampusGradeIds"},
  function (value) {
    const validIds = this.campusGradeArr.map(grade => grade.id);
    return validIds.includes(Number(value));
  }
)

const noSpecialChars = (value) => /^[a-zA-Z0-9_-]*$/.test(value);

export default {
  name: "AppFormCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    VueEditor,
  },

  data() {
    return {
      appUrl: CONFIG.APPLICATION_URL.replace(/\/$/, '') + '/',
      appTypeText: CONFIG.APPLICATION.TYPE_TXT,
      appTypeArr: [],
      campusGradeArr: [],
      flatpickrConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        time_24hr: true
      },
      appFormData: {
        title: '',
        url: '',
        type: 0,
        campus_grade_id: 0,
        start_date: '',
        end_date: '',
        initial_screen: '',
      },
      isError: false,
      submitted: false,
      campusId: this.$store.state.user.campus_id,
      disabled: false,
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ 'font': [] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["clean"] 
      ],
    };
  },

  computed: {
    ...mapState(['cameFromItemsPage']),
  },

  validations: {
    appFormData: {
      title: {
        required,
        maxLength: maxLength(255)
      },
      url: {
        required,
        maxLength: maxLength(255),
        noSpecialChars,
      },
      type: {
        required,
        inTypeKeys,
      },
      campus_grade_id: {
        required,
        inCampusGradeIds,
      },
      start_date: {
        required
      },
      end_date: {
        required
      }
    },
  },

  methods: {
    ...mapMutations(['setCameFromItemsPage']),

    getCampusGradeName() {
      axios.get('getAcademicYearCampusGradeName', {
          params: {
              campus_id: store.state.user.campus_id,
              start_date: new Date().toLocaleDateString()
          }
      }).then(res => {
          let $defaultSelectOption = [{
              "id": 0,
              "text": "Select Grade Name"
          }];
          this.campusGradeArr = $defaultSelectOption.concat(res.data);
      }).catch((error) => {
          if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
          }
          this.isLoading = false;
      });
    },

    setAppType() {
      let defaultArr = [
        {
          id: 0,
          text: "Select Form Type",
        }
      ];
      let typeArray = Object.keys(this.appTypeText).map(key => ({
        id: parseInt(key),
        text: this.appTypeText[key]
      }));
      this.appTypeArr = defaultArr.concat(typeArray);
    },

    submit() {
      this.submitted = true;
      if (this.appFormData.campus_grade_id == 0 ||
         (this.appFormData.start_date && 
          this.appFormData.end_date && 
          (this.dateCheck(this.appFormData.start_date) || this.dateCheck(this.appFormData.end_date, this.appFormData.start_date))
        )) {
        this.isError = true;
      } else {
        this.isError = false;
      }

      if (this.$v.$invalid || this.isError) {
        return;
      }

      this.disabled = true;
      let request = {
        campus_id: this.$store.state.user.campus_id,
        title: this.appFormData.title,
        url: this.appFormData.url,
        type: this.appFormData.type,
        campus_grade_id: this.appFormData.campus_grade_id,
        start_date: this.appFormData.start_date + ':00',
        end_date: this.appFormData.end_date + ':00',
        status: 'Create',
        initial_screen: this.appFormData.initial_screen,
      }
      axios.post('appForm/createValid', request)
            .then(res => {
              if (res.data.success == true) {
                this.$store.commit('setAppFormData', this.appFormData);
                this.$router.push({ name: 'appFormItems'});
              } else {
                this.disabled = false;
                this.$notification.error("Something Wrong on Create", {
                  timer: 3,
                  position: "bottomCenter",
                });
              }
            }).catch((error) => {
              this.disabled = false;
              this.isLoading = false;
              if (error.response) {
                if (error.response.status == 422) {
                  this.$notification.error(error.response.data.message, {
                    timer: 3,
                    position: "bottomCenter",
                  });
                } else if (error.response.status == 401) {
                  this.$store.commit('logout');
                  window.location.replace('login');
                }
              }
            });
    },

    dateCheck($firstDate, $secDate = null) {
        const firstDate = new Date($firstDate);
        const secondDate = $secDate ? new Date($secDate) : null;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return $secDate ? secondDate >= firstDate : firstDate < today;
    },

    setInitialScreen() {
      this.$store.commit('setInitialScreen', this.appFormData.initial_screen);
      const routeData = this.$router.resolve({name: 'initialScreenPreview'});
      window.open(routeData.href, '_blank');
    }
  },

  mounted() {
    if (this.cameFromItemsPage) {
      this.appFormData = { ...this.$store.state.xappFormData};
      this.setCameFromItemsPage(false);
    }
    this.getCampusGradeName();
    this.setAppType();
  },
};
