import MainLayout from "@/components/layout/admin/MainLayout.vue";
import flatPickr from 'vue-flatpickr-component';
import Select2Multiple from "v-select2-multiple-component";
import Loading from "vue-loading-overlay";
import 'flatpickr/dist/flatpickr.css';
import { required, maxLength, integer, minValue } from "vuelidate/lib/validators";
import axios from "axios";
import store from '@/store';

export default {
    name: "checkoutCreate",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        Loading
    },

    data() {
        return {
            checkoutData: {
                library_member_id: null,
                category_id: null,
                start_date: '',
                end_date: '',
                return_date: '',
                login_id: store.state.user.id,
            },
            inputData: {
                qr_code_no: '',
                quantity: '',
            },
            isError: false,
            submitted: false,
            qrSubmitted: false,
            campusId: store.state.user.campus_master.campus_code,
            disabled: false,
            memberArr: null,
            bookInfo: [],
            qr_ErrMsg: '',
            bookArr: null,
            isLoading: false,
            noMemberMsg: '',
            bookMsg: '',
            stockLimitMsg: '',
            bookRequiredMsg: '',
            books: [],
        };
    },

    validations: {
        checkoutData: {
            library_member_id: {
                required,
            },
            start_date: {
                required
            },
            end_date: {
                required
            },
        },
        inputData: {
            qr_code_no: {
                required,
                maxLength: maxLength(11)
            },
            quantity: {
                required,
                integer,
                minValue: minValue(1)
            }
        }
    },
    watch: {
        'inputData.qr_code_no': function (newValue) {
            if (newValue !== '') {
                this.bookMsg = '';
            }
        }
    },
    methods: {
        getMember() {
            let $defaultSelectOption = [{
                'id': 0,
                'text': 'Select Member'
            }];
            axios.get("/member/getList", {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    if (res.data.data.length > 0) {
                        this.memberArr = $defaultSelectOption.concat(res.data.data);
                    } else {
                        this.memberArr = $defaultSelectOption;
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        cloneForm() {
            this.qrSubmitted = true;
            if (this.$v.inputData.$invalid) {
                return;
            }
            this.addBookByQrCode(this.inputData);
        },

        editBook(index) {
            let bookToEdit = this.books[index];
            this.inputData.qr_code_no = bookToEdit.qr_code_no;
            this.inputData.quantity = bookToEdit.quantity;
            this.books.splice(index, 1);
        },

        removeBook(index) {
            this.books.splice(index, 1);
        },

        checkAvailable(bookId) {
            var request = {
                params: {
                    book_id: bookId,
                    campus_id: this.$store.state.user.campus_id
                }
            }
            return axios.get("/book/getStockBookById", request)
                .then((res) => {
                    return res.data.data;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    return null;
                });
        },

        checkBookExists(qrCodeNo) {
            return this.books.some(book => book.qr_code_no === qrCodeNo);
        },

        async addBookByQrCode(inputData) {
            this.isLoading = true;
            let qrCodeNo = inputData.qr_code_no;
            let qtytoCheckout = inputData.quantity;
            let request = {
                params: {
                    qr_code_no: qrCodeNo,
                    campus_id: this.$store.state.user.campus_id
                }
            }
            await axios.get("/book/getByQrCode", request)
                .then((res) => {
                    if (res.data && Object.keys(res.data).length > 0) {
                        let bookExists = this.checkBookExists(qrCodeNo);
                        if (bookExists) {
                            this.bookMsg = 'This book is already added.';
                            this.qrSubmitted = false;
                            this.isLoading = false;
                            return;
                        }
                        this.checkAvailable(res.data.id).then((data) => {
                            let stockAtLibrary = data.bookQty - data.checkoutCount;

                            if (stockAtLibrary == 0) {
                                this.bookMsg = 'Currently this book is not available.';
                                return;
                            }
                            if (qtytoCheckout > stockAtLibrary) {
                                this.stockLimitMsg = "Quantity must be less than stock.";
                                return;
                            }
                            let newBook = {
                                id: res.data.id,
                                qr_code_no: qrCodeNo,
                                book_name: res.data.title,
                                author: res.data.author,
                                category: res.data.category.name,
                                quantity: qtytoCheckout,
                            };
                            this.books.push(newBook);
                            this.resetInputData();
                        });
                    } else {
                        this.bookMsg = 'Book not found.';
                    }
                    this.submitted = false;
                    this.qrSubmitted = false;
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        resetInputData() {
            this.inputData.qr_code_no = '';
            this.inputData.quantity = '';
            this.bookMsg = '';
            this.stockLimitMsg = '';
        },

        create() {
            this.submitted = true;
            if (this.books.length <= 0) {
                this.bookRequiredMsg = 'Please add book data.';
            } else {
                this.isLoading = true;
                this.checkoutData.books = this.books.map(book => ({
                    id: book.id,
                    quantity: book.quantity
                }));

            }
            if (this.checkoutData.start_date && this.checkoutData.end_date && new Date(this.checkoutData.end_date) < new Date(this.checkoutData.start_date)) {
                this.isError = true;
            } else {
                this.isError = false;
            }
            if (this.$v.checkoutData.$invalid || this.isError) {
                this.isLoading = false;
                return;
            }
            this.disabled = true;
            axios.post('checkout/create', this.checkoutData)
                .then(res => {
                    if (res.data.success == true) {
                        this.$notification.success("Checkout Form Added Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: 'checkoutList'
                        })
                    } else {
                        this.disabled = false;
                        this.$notification.error(res.data.message, {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                }).catch((error) => {
                    this.disabled = false;
                    this.isLoading = false;
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.commit('logout');
                            window.location.replace('login');
                        }
                        if (error.response.status == 422) {
                            this.$notification.error(error.response.data.message, {
                                timer: 3,
                                position: "bottomCenter",
                            });
                        }
                    }
                });
        },
    },
    mounted() {
        this.getMember();
    }
};
