import MainLayout from "@/components/layout/admin/MainLayout.vue";
import flatPickr from 'vue-flatpickr-component';
import Select2Multiple from "v-select2-multiple-component";
import 'flatpickr/dist/flatpickr.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  required,
  maxLength,
  helpers
} from "vuelidate/lib/validators";
import { mapState, mapMutations } from 'vuex';
import CONFIG from "@/assets/js/config.js";
import axios from "axios";
import store from '../../store';
import { VueEditor } from 'vue2-quill-editor';
import 'quill/dist/quill.core.css'; // Core theme
import 'quill/dist/quill.snow.css'; // Snow theme (default)

const inTypeKeys = helpers.withParams(
  { type: "inTypeKeys" },
  value => Object.keys(CONFIG.APPLICATION.TYPE_TXT).includes(value.toString())
);

const inCampusGradeIds = helpers.withParams(
  {type: "inCampusGradeIds"},
  function (value) {
    const validIds = this.campusGradeArr.map(grade => grade.id);
    return validIds.includes(Number(value));
  }
)

const noSpecialChars = (value) => /^[a-zA-Z0-9_-]*$/.test(value);

export default {
  name: "AppFormEdit",
  components: {
    MainLayout,
    flatPickr,
    Loading,
    Select2Multiple,
    VueEditor,
  },

  data() {
    return {
      appUrl: CONFIG.APPLICATION_URL.replace(/\/$/, '') + '/',
      appTypeText: CONFIG.APPLICATION.TYPE_TXT,
      appTypeArr: [],
      campusGradeArr: [],
      flatpickrConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        time_24hr: true
      },
      appFormData: {
      },
      oldStartDate: null,
      isError: false,
      submitted: false,
      campusId: this.$store.state.user.campus_id,
      disabled: false,
      isLoading: false,
      isStartDateChange: false,
      hasAppliedUsers: false,
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ 'font': [] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"] // remove formatting button
      ],
    };
  },

  watch: {
    'appFormData.start_date' (newVal) {
      if (newVal + ':00' != this.oldStartDate) {
        this.isStartDateChange = true;
      } else {
        this.isStartDateChange = false;
      }
    }
  },

  computed: {
    ...mapState(['cameFromItemsPage']),
  },

  validations: {
    appFormData: {
      title: {
        required,
        maxLength: maxLength(255)
      },
      url: {
        required,
        maxLength: maxLength(255),
        noSpecialChars,
      },
      type: {
        required,
        inTypeKeys,
      },
      campus_grade_id: {
        required,
        inCampusGradeIds,
      },
      start_date: {
        required
      },
      end_date: {
        required
      }
    },
  },

  methods: {
    ...mapMutations(['setCameFromItemsPage']),

    async getAppFormDetail() {
      await axios.get('appForm/detail/' + this.$route.params.id)
                .then(res => {
                  const { applied_parents, applied_students, ...filterData } = res.data;
                  if (Object.keys(res.data).length == 0) {
                    this.$router.push({ name: 'appFormList'});
                  }
                  if(applied_parents != 0 || applied_students != 0) {
                      this.hasAppliedUsers = true;
                  }
                  this.appFormData = filterData;
                  this.oldStartDate = filterData.start_date;
                  this.isLoading = false;
                }).catch(error => {
                  if (error.response && error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                  }
                  this.isLoading = false;
                })
    },

    getCampusGradeName() {
      axios.get('getAcademicYearCampusGradeName', {
          params: {
              campus_id: store.state.user.campus_id,
              start_date: new Date().toLocaleDateString()
          }
      }).then(res => {
          let $defaultSelectOption = [{
              "id": 0,
              "text": "Select Grade Name"
          }];
          this.campusGradeArr = $defaultSelectOption.concat(res.data);
      }).catch((error) => {
          if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
          }
          this.isLoading = false;
      });
    },

    setAppType() {
      let defaultArr = [
        {
          id: 0,
          text: "Select Form Type",
        }
      ];
      let typeArray = Object.keys(this.appTypeText).map(key => ({
        id: parseInt(key),
        text: this.appTypeText[key]
      }));
      this.appTypeArr = defaultArr.concat(typeArray);
      this.isLoading = false;
    },

    submit() {
      this.submitted = true;
      if (this.appFormData.campus_grade_id == 0 || 
        (this.appFormData.start_date && 
          this.appFormData.end_date && 
          ((this.isStartDateChange ? this.dateCheck(this.appFormData.start_date) : false) || this.dateCheck(this.appFormData.end_date, this.appFormData.start_date))
        )) {
        this.isError = true;
      } else {
        this.isError = false;
      }

      if (this.$v.$invalid || this.isError) {
        return;
      }

      this.disabled = true;
      let request = {
        id : this.appFormData.id,
        campus_id: this.$store.state.user.campus_id,
        title: this.appFormData.title,
        url: this.appFormData.url,
        type: this.appFormData.type,
        campus_grade_id: this.appFormData.campus_grade_id,
        start_date: this.appFormData.start_date + ':00',
        end_date: this.appFormData.end_date + ':00',
        status: 'Update',
        initial_screen: this.appFormData.initial_screen,
      }

      axios.post('appForm/createValid', request)
            .then(res => {
              if (res.data.success == true) {
                this.$store.commit('setAppFormData', this.appFormData);
                this.$store.commit('setHasAppliedUsers', this.hasAppliedUsers);
                this.$router.push({ name: 'appFormEditItems', param: { id : this.appFormData.id }});
              } else {
                this.disabled = false;
                this.$notification.error("Something Wrong on Create", {
                  timer: 3,
                  position: "bottomCenter",
                });
              }
            }).catch((error) => {
              this.disabled = false;
              this.isLoading = false;
              if (error.response) {
                if (error.response.status == 422) {
                  this.$notification.error(error.response.data.message, {
                    timer: 3,
                    position: "bottomCenter",
                  });
                } else if (error.response.status == 401) {
                  this.$store.commit('logout');
                  window.location.replace('login');
                }
              }
            });
    },

    dateCheck($firstDate, $secDate = null) {
        const firstDate = new Date($firstDate);
        const secondDate = $secDate ? new Date($secDate) : null;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return $secDate ? secondDate >= firstDate : firstDate < today;
    },

    setInitialScreen() {
      this.$store.commit('setInitialScreen', this.appFormData.initial_screen);
      const routeData = this.$router.resolve({name: 'initialScreenPreview'});
      window.open(routeData.href, '_blank');
    }
  },

  async mounted() {
    this.isLoading = true;
    if (this.cameFromItemsPage) {
      this.appFormData = { ...this.$store.state.xappFormData};
      this.oldStartDate = this.appFormData.start_date + ':00';
      this.setCameFromItemsPage(false);
    } else {
      await this.getAppFormDetail();
    }
    this.getCampusGradeName();
    this.setAppType();
  },
};
